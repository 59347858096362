/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import { Link } from 'gatsby';
import CMSWrapper, { ImageEditor, TypeEditor } from '@ic-hosting/ich-contentful-cms-wrapper';
// import CMSWrapper, { ImageEditor, TypeEditor } from'../../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';

const renderComponent = ({ contentEntity, placeholder }) => (
  <div className="col-sm-6 col-md-4 mb-30">
    <TypeEditor type="instructor" space={contentEntity.space}>
    <div className="class-items bg-white border-1px p-20">
      <CMSWrapper {...contentEntity}>
        <div className="thumb">
          <img className="img-fullwidth" src={
            contentEntity.image ? contentEntity.image.size({
              width: 317,
              height: 317
            })
            : placeholder ? placeholder.size({
              width: 317,
              height: 317,
            }) : "http://placehold.it/317x317"
          } alt={contentEntity.image ? contentEntity.image.description : "no image"} />
          <div className="viewtime">
            <Link to={`/instructors/${contentEntity.slug}`} className="btn btn-theme-colored btn-flat mt-10"> View Details</Link>
          </div>
        </div>
        <h3 className="">
        <Link to={`/instructors/${contentEntity.slug}`}>{contentEntity.name}</Link></h3>
        </CMSWrapper>
    </div>
    </TypeEditor>
  </div>
);

export default renderComponent;