import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CMSWrapper from '@ic-hosting/ich-contentful-cms-wrapper';
import { updateData } from '../data/contentful/actions';
import Layout from '../components/layout';
import InstructorPanel from '../components/Instructor/panel'
import LocationPanel from '../components/Locations/panel';

//import CMSWrapper, { ImageEditor, TypeEditor } from'../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';

class LocationTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.state;
  }

  componentWillMount() {

  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.updateData();
  }

  render() {
    try {
    const {
      // data,
      pageContext,
      location,
      content,
    } = this.props;
    // const post = data.markdownRemark;
    const { slug } = pageContext;

    const assets = content.data.assets;
    const locationData = content.data.location[slug];
    const allLocations = content.data.location.all;
    const instructors = locationData && locationData.instructors && locationData.instructors.map(x => content.data.instructor[x.id]) || [];
    
    const placeholder = content.data.assets.Placeholder;
    return (
      <Layout location={location} title={locationData.title}>
        <section className="inner-header divider parallax layer-overlay overlay-dark-5" data-bg-img={
          locationData.image ? locationData.image.size({
            width: 1500
          })
        : null }
        >
          <CMSWrapper {...locationData}>
          <div className="container pt-100 pb-50">
            <div className="section-content pt-100">
              <div className="row"> 
                <div className="col-md-12">
                  <h3 className="title text-white"><span className="text-theme-colored">Location: </span>{locationData.title}</h3>
                </div>
              </div>
            </div>
          </div>
          </CMSWrapper>
        </section>
        <section>
          <div className="container pb-30">
            <div className="section-content">
              <div className="row">
                <div className="col-md-5 pr-60 pr-sm-0 mt-sm-60">
                  <div className="widget">
                    <h4 className="widget-title line-bottom text-uppercase bg-theme-colored p-10 text-white">Location Details</h4>
                    <div className="opening-hours">
                      <ul className="list-border">
                        <li className="clearfix"> <span> Region :  </span>
                          <div className="value pull-right flip"> {locationData.region.reduce((r,c) => `${r} ${c}`, '')}</div>
                        </li>
                        <li className="clearfix"> <span> Contact :</span>
                          <div className="value pull-right flip"> {locationData.contact} </div>
                        </li>
                        <li className="clearfix"> <span> Address :</span>
                          <div className="value pull-right flip" dangerouslySetInnerHTML={{ __html: locationData.address}} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="single-class-details">
                    <img src={
                      locationData.image ? locationData.image.size({
                        width: 650,
                        height: 440
                      })
                    : placeholder.size({
                      width: 650,
                        height: 440
                    })
                    } className="img-fullwidth" alt="" />
                    <h5>Information</h5>
                    <div dangerouslySetInnerHTML={{__html: locationData.information}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="classes" className="bg-lighter">
          <div className="container pb-40">
            <div className="section-title text-center">
              <div className="row">
                <div className="col-md-8 col-md-offset-2">
                  <h2 className="title text-uppercase">Location <span className="text-theme-colored">Instructors</span></h2>
                  <p className="text-uppercase letter-space-2">Join our martial art club and be healthy.</p>
                </div>
              </div>
            </div>
            {instructors && instructors.length ? 
              <div className="section-content">
                <div className="row">
                  <InstructorPanel instructors={instructors} placeholder={placeholder} />
                </div>
              </div> : null }
          </div>
        </section>
        <section id="services" className="divider parallax layer-overlay overlay-dark-6" data-parallax-ratio="0.1" data-bg-img={assets["4evn5UQCkSXW3aVjnMirNx"].size({ width: 1500 })}>
          <a name="locations" />
          <LocationPanel
            locations={allLocations}
            placeholder={placeholder}
          />
        </section>        
      </Layout>
    );
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  
}

const mapStateToProps = state => ({ state, content: state.contentful });

const mapDispatchToProps = dispatch => ({
  updateData: bindActionCreators(updateData, dispatch),
});



export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationTemplate);
