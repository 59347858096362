import React from 'react';
import CMSWrapper, { TypeEditor } from '@ic-hosting/ich-contentful-cms-wrapper';
// import CMSWrapper, { ImageEditor, TypeEditor } from'../../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';
import ClassPreview from ".";

const InstructorPanel = props => {
  const {instructors, placeholder} = props;
  return instructors && instructors.length ? 
  (
      <div className="section-content">
        <TypeEditor type="instructors" space={instructors[0].space}>
        <div className="row">
          {instructors.sort((a,b) => 
              b < a).map(item => (
            <ClassPreview key={item.id} contentEntity={item} placeholder={placeholder} />
          ))}
        </div>
        </TypeEditor>
      </div>
  ) : null;
};

export default InstructorPanel;